<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('first_name')
                  ? errors.first('first_name')
                  : ''
              "
              :variant="
                showValidation && errors.first('first_name') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombres'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.first_name"
                name="first_name"
                placeholder="Ingrese los nombres"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('first_name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('last_name')
                  ? errors.first('last_name')
                  : ''
              "
              :variant="
                showValidation && errors.first('last_name') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Apellidos'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.last_name"
                name="last_name"
                placeholder="Ingrese los apellidos"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('last_name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('dni') ? errors.first('dni') : ''
              "
              :variant="showValidation && errors.first('dni') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Cédula de identidad / Acta de nacimiento'"
            >
              <t-input
                v-validate="'numeric'"
                data-vv-validate-on="input"
                v-model="form.dni"
                name="dni"
                placeholder="Ingrese el número de documento"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="showValidation && errors.first('dni') ? 'danger' : ''"
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('birthdate')
                  ? errors.first('birthdate')
                  : ''
              "
              :variant="
                showValidation && errors.first('birthdate') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Fecha de nacimiento'"
            >
              <t-datepicker
                v-model="form.birthdate"
                initial-view="year"
                lang="es"
                :closeOnSelect="true"
                v-validate="'required'"
                data-vv-validate-on="input"
                :maxDate="new Date()"
                name="birthdate"
                id="birthdate"
                placeholder="Seleccione la fecha"
                :disabled="loading"
                :variant="
                  showValidation && errors.first('birthdate') ? 'danger' : ''
                "
              >
              </t-datepicker>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('address')
                  ? errors.first('address')
                  : ''
              "
              :variant="
                showValidation && errors.first('address') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Dirección'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.address"
                name="address"
                placeholder="Ingrese la dirección"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('address') ? 'danger' : ''
                "
              />
            </t-input-group>

            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation && errors.first('height')
                    ? errors.first('height')
                    : ''
                "
                :variant="
                  showValidation && errors.first('height') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Estatura (en metros)'"
              >
                <t-input
                  v-validate="'required|amount'"
                  data-vv-validate-on="input"
                  v-model="form.height"
                  name="height"
                  placeholder="Ingrese la altura"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidation && errors.first('height') ? 'danger' : ''
                  "
                />
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation && errors.first('weight')
                    ? errors.first('weight')
                    : ''
                "
                :variant="
                  showValidation && errors.first('weight') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Peso (en kilogramos)'"
              >
                <t-input
                  v-validate="'required|amount'"
                  data-vv-validate-on="input"
                  v-model="form.weight"
                  name="weight"
                  placeholder="Ingrese el peso"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidation && errors.first('weight') ? 'danger' : ''
                  "
                />
              </t-input-group>
            </div>
            <div class="flex justify-start my-4">
              <p>Mostrar en la app</p>
              <t-toggle
                class="mx-3"
                v-model="visible"
                @click="toggleVisibility(!visible)"
                :disabled="loading"
              />
              <p>Sí</p>
            </div>
            <t-input-group
              :feedback="
                showValidation && errors.first('url_dni_picture')
                  ? errors.first('url_dni_picture')
                  : ''
              "
              :variant="
                showValidation && errors.first('url_dni_picture')
                  ? 'danger'
                  : ''
              "
              class="w-full md:w-full md:mr-0 md:pr-2 my-4"
              :label="'Foto cédula / Acta de nacimiento'"
            >
              <t-upload-file
                :disabled="loading"
                :file.sync="form.url_dni_picture"
                :externalUrl="
                  typeof form.url_dni_picture === 'string'
                    ? form.url_dni_picture
                    : ''
                "
                backgroundColor="bg-white"
                placeholder="Selecciona una imagen"
                height="250px"
                accept="image/*"
              />
            </t-input-group>
          </div>

          <div class="w-full md:w-1/2 mx-1 relative">
            <div
              class="flex flex-col md:flex-row items-end justify-around my-2"
            >
              <t-input-group
                :feedback="
                  showValidation && errors.first('url_profile_picture')
                    ? errors.first('url_profile_picture')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_profile_picture')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
                :label="'Foto del jugador'"
              >
                <cropper-upload
                  :file.sync="form.url_profile_picture"
                  :externalUrl="
                    typeof form.url_profile_picture === 'string'
                      ? form.url_profile_picture
                      : null
                  "
                  title="Foto del jugador"
                  :aspectRatio="3/4"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation && errors.first('url_celebration_picture')
                    ? errors.first('url_celebration_picture')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_celebration_picture')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
              >
                <label class="block text-left font-bold"
                  >Foto del jugador (Celebración)</label
                >
                <cropper-upload
                  :file.sync="form.photos[0].url_photo"
                  :externalUrl="
                    typeof form.photos[0].url_photo === 'string'
                      ? form.photos[0].url_photo
                      : null
                  "
                  title="Foto de celebración"
                  :aspectRatio="3/4"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </t-input-group>
            </div>

            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation && errors.first('id_category')
                    ? errors.first('id_category')
                    : ''
                "
                :variant="
                  showValidation && errors.first('id_category') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Categoría'"
              >
                <t-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.id_category"
                  name="id_category"
                  placeholder="Seleccione una opción"
                  :disabled="true"
                  textAttribute="name"
                  valueAttribute="id"
                  :options="categories"
                  :variant="
                    showValidation && errors.first('id_category')
                      ? 'danger'
                      : ''
                  "
                ></t-select>
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation && errors.first('id_subcategory')
                    ? errors.first('id_subcategory')
                    : ''
                "
                :variant="
                  showValidation && errors.first('id_subcategory')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Subcategoría'"
              >
                <t-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.id_subcategory"
                  name="id_subcategory"
                  placeholder="Seleccione una opción"
                  :disabled="true"
                  textAttribute="name"
                  valueAttribute="id"
                  :options="subcategories"
                  :variant="
                    showValidation && errors.first('id_subcategory')
                      ? 'danger'
                      : ''
                  "
                ></t-select>
              </t-input-group>
            </div>

            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation && errors.first('gender')
                    ? errors.first('gender')
                    : ''
                "
                :variant="
                  showValidation && errors.first('gender') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Sexo'"
              >
                <t-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.gender"
                  name="gender"
                  placeholder="Seleccione una opción"
                  :disabled="loading"
                  textAttribute="name"
                  valueAttribute="id"
                  :options="genders"
                  :variant="
                    showValidation && errors.first('gender') ? 'danger' : ''
                  "
                ></t-select>
              </t-input-group>
              <t-input-group
                :feedback="
                  showValidation && errors.first('id_academy')
                    ? errors.first('id_academy')
                    : ''
                "
                :variant="
                  showValidation && errors.first('id_academy') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Equipo'"
              >
                <t-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.id_academy"
                  name="id_academy"
                  placeholder="Seleccione una opción"
                  :disabled="true"
                  textAttribute="name"
                  valueAttribute="id"
                  :options="academies"
                  :variant="
                    showValidation && errors.first('id_academy') ? 'danger' : ''
                  "
                ></t-select>
              </t-input-group>
            </div>

            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation && errors.first('position')
                    ? errors.first('position')
                    : ''
                "
                :variant="
                  showValidation && errors.first('position') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Posición'"
              >
                <t-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.position"
                  name="position"
                  placeholder="Seleccione una opción"
                  :disabled="loading"
                  textAttribute="name"
                  valueAttribute="id"
                  :options="positions"
                  :variant="
                    showValidation && errors.first('position') ? 'danger' : ''
                  "
                ></t-select>
              </t-input-group>
              <t-input-group
                :feedback="
                  showValidation && errors.first('favorite_player')
                    ? errors.first('favorite_player')
                    : ''
                "
                :variant="
                  showValidation && errors.first('favorite_player')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Jugador favorito'"
              >
                <t-input
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.favorite_player"
                  name="favorite_player"
                  placeholder="Ingrese el nombre del jugador favorito"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidation && errors.first('favorite_player')
                      ? 'danger'
                      : ''
                  "
                />
              </t-input-group>
            </div>

            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation && errors.first('skillful_foot')
                    ? errors.first('skillful_foot')
                    : ''
                "
                :variant="
                  showValidation && errors.first('skillful_foot')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Pie dominante'"
              >
                <t-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.skillful_foot"
                  name="skillful_foot"
                  placeholder="Seleccione una opción"
                  :disabled="loading"
                  textAttribute="name"
                  valueAttribute="id"
                  :options="[
                    {
                      id: 'Izquierdo',
                      name: 'Izquierdo'
                    },
                    {
                      id: 'Derecho',
                      name: 'Derecho'
                    }
                  ]"
                  :variant="
                    showValidation && errors.first('skillful_foot')
                      ? 'danger'
                      : ''
                  "
                ></t-select>
              </t-input-group>
              <t-input-group
                :feedback="
                  showValidation && errors.first('favorite_team')
                    ? errors.first('favorite_team')
                    : ''
                "
                :variant="
                  showValidation && errors.first('favorite_team')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Equipo favorito'"
              >
                <t-input
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="form.favorite_team"
                  name="favorite_team"
                  placeholder="Ingrese el nombre del equipo favorito"
                  autocomplete="off"
                  :disabled="loading"
                  type="text"
                  :variant="
                    showValidation && errors.first('favorite_team')
                      ? 'danger'
                      : ''
                  "
                />
              </t-input-group>
            </div>

            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation && errors.first('jersey_number')
                    ? errors.first('jersey_number')
                    : ''
                "
                :variant="
                  showValidation && errors.first('jersey_number')
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:ml-2 md:mr-2"
                :label="'Número de camisa'"
              >
                <t-input
                  v-validate="'required|min:0|max:99'"
                  data-vv-validate-on="input"
                  v-model="form.jersey_number"
                  name="jersey_number"
                  placeholder="Ingrese número del 1 al 99"
                  autocomplete="off"
                  :disabled="loading"
                  type="number"
                  :variant="
                    showValidation && errors.first('jersey_number')
                      ? 'danger'
                      : ''
                  "
                />
              </t-input-group>
            </div>

            <div
              class="absolute bottom-0 w-full flex justify-center"
              v-if="deletable"
            >
              <t-button type="button" variant="error" @click="deleteElement"
                >Eliminar jugador</t-button
              >
            </div>
          </div>
        </div>
      </template>
    </basic-form>

    <!-- Representatives -->

    <t-card class="w-full" variant="form">
      <div class="flex justify-between px-3 bg-white py-4 rounded-t-md mt-3">
        <div class="flex items-center">
          <p class="uppercase font-bold text-sm my-auto text-center mr-2">
            Datos de los representantes
          </p>
          <icon
            @click="addRepresentative"
            style="width: 26px; height: 26px; cursor: pointer"
            icon="plus"
            class="rounded-full bg-red-1100 text-white text-3xl p-2 my-auto"
          />
        </div>
      </div>

      <div
        class="flex flex-col px-4 bg-gray-100 pb-2"
        v-for="(representative, index) in form.representatives"
        :key="representative.key"
      >
        <div class="flex items-center justify-end mt-3">
          <t-button
            type="button"
            class="mr-3"
            variant="error"
            @click="deleteRepresentative(index)"
            >Eliminar representante</t-button
          >

          <t-button
            v-if="deletable"
            variant="warning"
            type="button"
            @click="saveRepresentative(index)"
          >
            Guardar cambios
            <t-loader v-if="loadingRepresentatives" extraClass="ml-2" />
          </t-button>
        </div>

        <div
          class="flex justify-center pb-4"
          :class="
            form.representatives[index + 1] ? 'border-gray-300 border-b-2' : ''
          "
        >
          <div class="w-1/2 md:px-5">
            <t-input-group
              :feedback="
                showValidation &&
                errors.first(`representative_${representative.key}_type`)
                  ? errors.first(`representative_${representative.key}_type`)
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_type`)
                  ? 'danger'
                  : ''
              "
              class="w-full md:w-1/2 md:ml-2 md:mr-2"
              :label="'Tipo'"
            >
              <t-select
                v-validate="'simple_required'"
                data-vv-validate-on="input"
                v-model="representative.type"
                :name="`representative_${representative.key}_type`"
                placeholder="Seleccione un tipo"
                :disabled="loadingRepresentatives"
                textAttribute="name"
                valueAttribute="id"
                :options="representativeOptions()"
                :variant="
                  showValidation &&
                  errors.first(`representative_${representative.key}_type`)
                    ? 'danger'
                    : ''
                "
              ></t-select>
            </t-input-group>
            <t-input-group
              :feedback="
                showValidation &&
                errors.first(`representative_${representative.key}_first_name`)
                  ? errors.first(
                      `representative_${representative.key}_first_name`
                    )
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_first_name`)
                  ? 'danger'
                  : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombres'"
            >
              <t-input
                v-validate="'simple_required|max:200'"
                data-vv-validate-on="input"
                v-model="representative.first_name"
                :name="`representative_${representative.key}_first_name`"
                placeholder="Ingrese los nombres"
                autocomplete="off"
                :disabled="loadingRepresentatives"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(
                    `representative_${representative.key}_first_name`
                  )
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation &&
                errors.first(`representative_${representative.key}_last_name`)
                  ? errors.first(
                      `representative_${representative.key}_last_name`
                    )
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_last_name`)
                  ? 'danger'
                  : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Apellidos'"
            >
              <t-input
                v-validate="'simple_required|max:200'"
                data-vv-validate-on="input"
                v-model="representative.last_name"
                :name="`representative_${representative.key}_last_name`"
                placeholder="Ingrese los apellidos"
                autocomplete="off"
                :disabled="loadingRepresentatives"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(`representative_${representative.key}_last_name`)
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation &&
                errors.first(`representative_${representative.key}_dni`)
                  ? errors.first(`representative_${representative.key}_dni`)
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_dni`)
                  ? 'danger'
                  : ''
              "
              class="w-full md:w-1/2 md:mr-2 md:ml-2"
              :label="'Cédula de identidad'"
            >
              <t-input
                v-validate="'simple_required'"
                data-vv-validate-on="input"
                v-model="representative.dni"
                :name="`representative_${representative.key}_dni`"
                placeholder="Ingrese el número de cedula de identidad"
                autocomplete="off"
                :disabled="loadingRepresentatives"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(`representative_${representative.key}_dni`)
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>

            <t-input-group
              class="w-full md:w-1/2 md:mr-0 md:pr-2"
              :label="'Foto de la cédula'"
            >
              <t-upload-file
                :disabled="loading"
                :file.sync="representative.url_dni_picture"
                :externalUrl="
                  typeof representative.url_dni_picture === 'string'
                    ? representative.url_dni_picture
                    : ''
                "
                backgroundColor="bg-white"
                placeholder="Selecciona una imagen"
                height="250px"
                accept="image/*"
              />
            </t-input-group>
          </div>
          <div class="w-1/2 md:px-5">
            <div class="flex flex-col md:flex-row items-end my-4">
              <t-input-group
                :feedback="
                  showValidation &&
                  errors.first(`representative_${representative.key}_phone`)
                    ? errors.first(`representative_${representative.key}_phone`)
                    : ''
                "
                :variant="
                  showValidation &&
                  errors.first(`representative_${representative.key}_phone`)
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:mr-2 md:ml-2"
                :label="'Celular'"
              >
                <t-input
                  v-validate="'phone'"
                  data-vv-validate-on="input"
                  v-model="representative.phone"
                  :name="`representative_${representative.key}_phone`"
                  placeholder="Ingrese el teléfono celular"
                  autocomplete="off"
                  :disabled="loadingRepresentatives"
                  type="text"
                  :variant="
                    showValidation &&
                    errors.first(`representative_${representative.key}_phone`)
                      ? 'danger'
                      : ''
                  "
                />
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation &&
                  errors.first(
                    `representative_${representative.key}_local_phone`
                  )
                    ? errors.first(
                        `representative_${representative.key}_local_phone`
                      )
                    : ''
                "
                :variant="
                  showValidation &&
                  errors.first(
                    `representative_${representative.key}_local_phone`
                  )
                    ? 'danger'
                    : ''
                "
                class="w-full md:w-1/2 md:ml-2"
                :label="'Otro teléfono'"
              >
                <t-input
                  v-validate="'phone'"
                  data-vv-validate-on="input"
                  v-model="representative.local_phone"
                  :name="`representative_${representative.key}_local_phone`"
                  placeholder="Ingrese otro número de teléfono"
                  autocomplete="off"
                  :disabled="loadingRepresentatives"
                  type="text"
                  :variant="
                    showValidation &&
                    errors.first(
                      `representative_${representative.key}_local_phone`
                    )
                      ? 'danger'
                      : ''
                  "
                />
              </t-input-group>
            </div>

            <t-input-group
              :feedback="
                showValidation &&
                errors.first(`representative_${representative.key}_email`)
                  ? errors.first(`representative_${representative.key}_email`)
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_email`)
                  ? 'danger'
                  : ''
              "
              class="w-full md:mr-2 md:ml-2"
              :label="'Correo electrónico'"
            >
              <t-input
                v-validate="'simple_required|simple_email'"
                data-vv-validate-on="input"
                v-model="representative.email"
                :name="`representative_${representative.key}_email`"
                placeholder="Ingrese el correo electrónico"
                autocomplete="off"
                :disabled="loadingRepresentatives"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(`representative_${representative.key}_email`)
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation &&
                errors.first(`representative_${representative.key}_address`)
                  ? errors.first(`representative_${representative.key}_address`)
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_address`)
                  ? 'danger'
                  : ''
              "
              class="w-full md:mr-2 md:ml-2"
              :label="'Dirección'"
            >
              <t-input
                v-validate="'simple_required'"
                data-vv-validate-on="input"
                v-model="representative.address"
                :name="`representative_${representative.key}_address`"
                placeholder="Ingrese la dirección"
                autocomplete="off"
                :disabled="loadingRepresentatives"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(`representative_${representative.key}_address`)
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation &&
                errors.first(`representative_${representative.key}_workplace`)
                  ? errors.first(
                      `representative_${representative.key}_workplace`
                    )
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_workplace`)
                  ? 'danger'
                  : ''
              "
              class="w-full md:mr-2 md:ml-2"
              :label="'Empresa donde labora'"
            >
              <t-input
                v-validate="'simple_required'"
                data-vv-validate-on="input"
                v-model="representative.workplace"
                :name="`representative_${representative.key}_workplace`"
                placeholder="Ingrese el nombre de la empresa"
                autocomplete="off"
                :disabled="loadingRepresentatives"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(`representative_${representative.key}_workplace`)
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation &&
                errors.first(`representative_${representative.key}_profession`)
                  ? errors.first(
                      `representative_${representative.key}_profession`
                    )
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_profession`)
                  ? 'danger'
                  : ''
              "
              class="w-full md:mr-2 md:ml-2"
              :label="'Cargo que ocupa'"
            >
              <t-input
                v-validate="'simple_required'"
                data-vv-validate-on="input"
                v-model="representative.profession"
                :name="`representative_${representative.key}_profession`"
                placeholder="Ingrese el cargo que ocupa"
                autocomplete="off"
                :disabled="loadingRepresentatives"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(
                    `representative_${representative.key}_profession`
                  )
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>
          </div>
        </div>
      </div>
    </t-card>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    loadingRepresentatives: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        representatives: [],
        photos: [
          {
            type_photo: 3
          }
        ]
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    representativeTypes() {
      return [
        {
          name: "Madre",
          id: 1
        },
        {
          name: "Padre",
          id: 2
        },
        {
          name: "Otro",
          id: 3
        }
      ]
    },
    categories() {
      return this.$store.getters["global/getItems"](
        "category",
        "unpaginated_elements"
      )
    },
    subcategories() {
      return this.$store.getters["global/getItems"](
        "subcategory",
        "unpaginated_elements"
      )
    },
    academies() {
      return this.$store.getters["global/getItems"](
        "academy",
        "unpaginated_elements"
      )
    },
    genders() {
      return [
        {
          id: 1,
          name: "Femenino"
        },
        {
          id: 2,
          name: "Masculino"
        }
      ]
    },
    positions() {
      return Object.entries(this.$store.state.player.positions).map((el) => ({
        id: parseInt(el[0]),
        name: el[1]
      }))
    }
  },
  methods: {
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "academy_category",
          module: "academyCategory",
          id: this.$route.params.id2
        })
        .then((response) => {
          this.form.id_academy = response.data.id_academy
          this.form.id_category = response.data.id_category
          this.form.id_subcategory = response.data.id_subcategory
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    representativeOptions() {
      for (let element of this.representativeTypes) {
        element.disabled = false
        if (
          Object.values(this.form.representatives.map((x) => x.type)).some(
            (x) => parseInt(x) === element.id
          )
        )
          element.disabled = true
      }
      return this.representativeTypes
    },
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          if (!this.form.url_profile_picture) {
            return this.$snotify.error(
              "La foto del jugador es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          if (!this.form.url_dni_picture) {
            return this.$snotify.error(
              "La foto de la cédula es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          this.$emit("save")
        }
      })
    },

    saveRepresentative(index) {
      this.$emit("saveRepresentative", index)
    },
    addRepresentative() {
      if (this.form.representatives.length === 2) {
        return this.$snotify.error(
          "No se puede asignar más de 2 representantes",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
      this.form.representatives.push({
        key: Date.now(),
        type: "",
        first_name: "",
        last_name: "",
        dni: "",
        url_dni_picture: null,
        phone: "",
        local_phone: "",
        email: "",
        address: "",
        workplace: "",
        profession: ""
      })
    },
    deleteRepresentative(index) {
      this.$emit("deleteRepresentative", index)
    },
    updatePlayerPhoto() {
      for (let element of this.form.photos) {
        if (element.id) {
          this.$store
            .dispatch("global/update", {
              payload: {
                id: element.id,
                url_photo: element.url_photo
              },
              route: "/player_photo/update"
            })
            .catch((error) => {
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        } else {
          this.$store
            .dispatch("global/create", {
              payload: {
                id_player: this.$route.params.id,
                type_photo: element.type_photo,
                url_photo: element.url_photo
              },
              route: "/player_photo/save"
            })
            .catch((error) => {
              console.log("player photo save error:", error)
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      }
    },
    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al jugador.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/player",
                module: "player"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200)
                  this.$router.push(
                    "/academias/" +
                      this.$route.params.id +
                      "/categorias/" +
                      this.$route.params.id2 +
                      "/jugadores"
                  )
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getItems("/category/all", "category", true)
    this.getItems("/subcategory/all", "subcategory", true)
    this.getItems("/academy/all", "academy", false)
    this.getItem()
  }
}
</script>
